@import "../../../variables";

.SharePlan {
  max-width: 400px;
  padding: 3rem 1.5rem;
  @media (min-width: $mobile-width-cutoff) {
    padding: 5rem;
  }

  &__plan-image {
    position: absolute;
    left: calc(50% - 61px);
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 24px;
    border: 1px solid $white;
  }
  &__send-plan-image {
    width: 232px;
  }

  &__copy-button-icon {
    font-size: 1.5rem;
  }
}
