@import "../../variables";
@import "../../spacing";

.TextArea {
  position: relative; 

  &__error {
    color: $red;
    font-size: 14px;
  }

  >.TextArea__label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    color: $text-tertiary-color;
    font-size: 1rem;
  }

  >.TextArea__input {
    padding: 1.125rem .75rem;
    height: 80px;
    resize: none;
    font-family: $font-family;
  }

  >.TextArea__input::placeholder {
    color: transparent
  }

  >.TextArea__input:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }
  
  >.TextArea__input:focus, >.TextArea__input:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }
  
  >.TextArea__input:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
  }
  

  .TextArea__input>.TextArea__input:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
  }
  
  >.TextArea__input:focus~label,
  >.TextArea__input:not(:placeholder-shown)~label,
  >.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
  }
  
  >.TextArea__input:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
  }

  .TextArea__input {
    display: block;
    width: calc(100% - 1.5rem);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }

  .TextArea__input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
  }

}
