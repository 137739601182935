@import "../../variables";

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .Modal-dialog {
    max-height: 70%;
    display: flex;
  }

  .Modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    width: 440px;

    .Modal-body {
      width: 100%;
      overflow: scroll;
    }
  }

  .Bottom-drawer-close-button {
    background-color: transparent;
    color: $text-secondary-color;
    border: none;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    z-index: 9999;
  }

  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
  }
}

.EnhancedModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .EnhancedModal-dialog {
    max-height: 70%;
    display: flex;
  }

  .EnhancedModal-content {
    background-color: $blueberry-450;
    background-image: linear-gradient(58.45deg, rgba(77, 48, 255, 0.48) 9.54%, rgba(37, 0, 140, 0.48) 80.66%),
    radial-gradient(142.54% 116.97% at 78.28% 35.1%, #4D30FF 0%, $blueberry-650 33.33%, $blueberry-450 66.67%, $blueberry-650 100%);
    padding: 3rem;
    border-radius: 16px;
    width: 650px;
    overflow-x: hidden;

    .Modal-body {
      width: 100%;
      overflow: scroll;
    }
  }

  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: $black;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
  }

  @media (max-width: $mobile-width-cutoff) {
    .EnhancedModal-content {
      padding: 2rem;
    }
  }
}
