@import "../../variables";

.PlanDetailPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  @media (min-width: $mobile-width-cutoff) {
    gap: 1.5rem;
    margin-bottom: 5rem;
  }
}
