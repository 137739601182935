@import "../../../variables";

.ChannelChatButton {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    cursor: pointer;
    position: relative;

    &__icon {
      font-size: 24px;
      padding-right: 8px;
    }

    &__badge {
      position: absolute;
      top: -4px;
      right: 4px;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: $bright-pink;
    }
  }
}
