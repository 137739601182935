@import "../../../variables";
@import "../../../spacing";

.EditPortrait {
  justify-content: space-between;
  &__picture {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__file-input-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &__input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
