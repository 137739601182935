@import "../../../variables";

.ChannelMembers {
  &__members-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.ChannelMember {
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  outline: .5rem solid $white;

  &:hover {
    .ChannelMember__container__rsvp-icon {
        background-color: $blueberry-30;
        transition: ease-out background-color 250ms;
    }
    background-color: $blueberry-30;
    border-radius: .5rem;
    outline: .5rem solid $blueberry-30;
    transition: ease-out background 250ms, ease-out outline 250ms;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;

    &__profile-picture {
      object-fit: cover;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
    }

    &__rsvp-icon {
      height: 1.75rem;
      width: 1.75rem;
      background-color: $white;
      border-radius: 50%;
      color: $bright-pink;
      position: absolute;
      top: 2.25rem;
      left: 2.25rem;
      font-size: 1.5rem;
    }

    &__member-name {
      color: $text-primary-color;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      max-width: 4rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .Channel-member-rsvp-yes {
    height: 24px;
    width: 24px;
    border: 1px;
    border-radius: 25px;
    position: absolute;
    top: 40px;
    left: 35px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }

}
