@import "variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/forms/_form-check";
@import "bootstrap/scss/dropdown";

@font-face {
  font-family: 'Avantt';
  src: url('assets/font/Avantt-Medium.ttf') format('truetype'),
       url('assets/font/Avantt-Medium.woff') format('woff'),
       url('assets/font/Avantt-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avantt';
  src: url('assets/font/Avantt-SemiBold.ttf') format('truetype'),
       url('assets/font/Avantt-SemiBold.woff') format('woff'),
       url('assets/font/Avantt-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avantt';
  src: url('assets/font/Avantt-Bold.ttf') format('truetype'),
  url('assets/font/Avantt-Bold.woff') format('woff'),
  url('assets/font/Avantt-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

html {
  min-height: 100%;
  font-family: $font-family;
  @media (min-width: $mobile-width-cutoff) {
    background-color: $blueberry-40;
  }
}

body {
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-primary-color;
  font-weight: 500;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0
}

h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  color: $text-primary-color;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $text-primary-color;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $text-primary-color;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-primary-color;
  font-weight: 700;
  margin: 0;
}

h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-primary-color;
  font-weight: 600;
  margin: 0;
}

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-family: $font-family;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

::selection {
  background: $bright-pink;
  color: $buttercream;
}

.Card {
  @media (min-width: $mobile-width-cutoff) {
    background-color: $white;
    outline: 0.0625rem solid rgba(0, 0, 0, 0.04);
    border-radius: 1.5rem;
    box-shadow:0 .25rem 1.25rem rgba(30,0,114,.05);
    max-width: 800px;
  }
}

.Enhanced-card {
  @media (min-width: $mobile-width-cutoff) {
    outline: 0.0625rem solid rgba(0, 0, 0, 0.04);
    box-shadow:0 .25rem 1.25rem rgba(30,0,114,.05);
    width: 100%;
  }
}

.Mobile-divider {
  width: 100%;
  height: 1.5rem;
  background-color: $blueberry-40;
}

.grecaptcha-badge { visibility: hidden; }
