@import "../../../variables";
@import "../../../spacing";

.AuthInterests {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header-text {
    text-align: center;
  }

  &__subheader-text {
    text-align: center;
    color: $gray-600;
  }

}
