.Pinned-messages-header-container {
  margin: 16px 0;
  display: flex;
}

.Pinned-messages-body-container {
  display: flex;
  padding: 2px 0px;
  margin-bottom: 8px;
  align-items: center;
}

.Pinned-messages-user-image {
  object-fit: cover;
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}
