@import "../../../variables";

.PlanListTabNav {
  @media (max-width: $mobile-width-cutoff) {
    border-bottom: 1px solid $gray-50;
    margin-bottom: 1.5rem;
  }
  @media (min-width: $mobile-width-cutoff) {
    margin-bottom: 1.5rem;
  }
  &__item {
    border-radius: .125rem .125rem 0 0;
    &:hover {
      background-color: $blueberry-50;
      animation-name: tab-hover-animation;
      animation-duration: 400ms;
    }
    &__link {
      &__icon {
        color: $blueberry-750;
      }
      &__text {
        font-weight: 600;
        color: $blueberry-750;
      }
      &__bottom-border {
        animation-name: bottom-border-animation;
        animation-duration: 800ms;
        height: 4px;
        border-radius: 2px;
        background-color: $blueberry-750;
      }
    }
  }
}
