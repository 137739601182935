@import "../../variables";
@import "../../spacing";

.PlanAttribution {
  padding-top: $sp-4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__container {
    display: flex;
    align-items: center;

    &__image {
      object-fit: cover;
      height: 2.25em;
      width: 2.25em;
      border-radius: 2.25rem;
      margin-right: $sp-2;
    }
  }

  &__new-label {
    color: $bright-pink;
  }
  
  @media (max-width: $mobile-width-cutoff) {
    padding-top: $sp-1;
  }
}
