@import "../../../variables";
@import "../../../spacing";

.EditProfile {
  &__container {
      margin-bottom: $sp-12;
      padding: $sp-12 $sp-6 0 $sp-6;
        @media (min-width: $mobile-width-cutoff) {
          padding: $sp-20 $sp-20 0 $sp-20;
        }

    &__info {
      gap: $sp-3;
      @media (max-width: $mobile-width-cutoff) {
        flex-direction: column;
        gap: 0px;
      }

      &__first-name {
        flex-grow: 1;


        @media (max-width: $mobile-width-cutoff) {
          margin-right: 0px;
          margin-bottom: $sp-6;
        }
      }

      &__last-name {
        flex-grow: 1;


        @media (max-width: $mobile-width-cutoff) {
          margin-left: 0px;
        }
      }
    }

    &__city {
      width: 347px
    }


    &__socials {
      &__instagram {
        max-width: 345px;
      }
      &__twitter {
        max-width: 345px;
      }
      &__linkedin {
        max-width: 345px;
      }
    }
  }
  &__sticky-buttons {
    position: sticky;
    bottom: 0px;
  }

  .deleteBttn { 
    background-color: $red;
    color: $buttercream;
    margin-top: 1.5rem;
  }
}
