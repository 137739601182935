@import "../../variables";
@import "../../spacing";

.Channel {
  width: 100%;

  &__content {
    margin-bottom: 4.5rem;
    padding: $sp-6 1.5rem 0 1.5rem;
    @media (min-width: $mobile-width-cutoff) {
      padding: $sp-6 5rem 0 5rem;
    }
  }
}

.Channel-image {
  object-fit: cover;
  height: 160px;
  width: 160px;
  border-radius: 8px;
}

.Links-container {
  margin-top: 0.75rem;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;

  .Link-anchor-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    border: 2px solid $raised-surface-border-color;
    border-radius: 48px;
    box-sizing: border-box;
    padding: 8px 16px;
    color: $blueberry-550;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      border: 2px solid $bright-pink-tenth-opacity;
    }
  }
}


.Chat-view-messages {
  padding: 10px 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;
  color: $text-secondary-color;
  font-size: 14px;
  border: #72767E 1px solid;
  border-radius: 16px;

}

.Latest-messages-header {
  margin: 16px 0;
}
