@import "../../variables";

.str-chat *,
.str-chat__channel-header .str-chat__channel-header-title,
.str-chat__channel-header .str-chat__channel-header-info {
  font-family: $font-family;
}

.str-chat {
  border-radius: 16px;
  height: 100%;
  width: 100%;
}

.custom-chat-stream-container {
  height: 100%;

  .str-chat__message .str-chat__message-bubble {
    background-color: $gray-30;
    color: $gray-800;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .str-chat__message.str-chat__message--me .str-chat__message-bubble {
    background-color: $blueberry-550;
    color: $buttercream;
  }

  .str-chat__message-attachment-card--title {
    color: $buttercream;
  }

  .str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction.str-chat__message-reaction-own {
    background-color: $blueberry-550;
    color: $buttercream;
  }

  .str-chat__message .str-chat__message-mention {
    color: inherit;
    font-weight: 600;
  }
  
  .str-chat__main-panel {
    height: 100%;
  }

  .str-chat__main-panel-inner {
    align-items: flex-start;
    min-height: 450px;
  }

  .str-chat__date-separator {
    display: none;
  }
  .str-chat__message {
    row-gap: 0.0625rem;
    column-gap: 0.25rem;
  }
  .str-chat__li {
    list-style-type: none;
  }
  ul {
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .str-chat__message--system {
    margin-bottom: 5px;
  }

  .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular).str-chat__message-actions-box--mine {
    inset-inline-start: -360%;
  }

  .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular):not(.str-chat__message-actions-box--mine) {
    inset-inline-end: -150%;
  }

  .str-chat__header-livestream-left{
    margin-left: 10px;
  }

  .str-chat__li.str-chat__li--single, .str-chat__li.str-chat__li--top {
    margin-top: 1rem;
  }

  .str-chat__header-livestream {
    padding-bottom: 13px;
    padding-top: 13px;
    position: sticky;
    top: -17px;
    z-index: 999;
  }

  .str-chat__message-simple-status {
    display: none;
  }

  .str-chat__message-status {
    display: none;
  }

  .str-chat__list-notifications {
    display: none;
  }

  .str-chat__message-input {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .str-chat__message-reactions {
    display: none; // will be remove once we start using reactions
  }

  .str-chat__modal {
    z-index: 1000;
  }

  .message-input {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0px;
  }

  .Chat-pie-logo {
    padding: 10px 0;
    display: flex;
    justify-content: center;

    .Chat-pie-img {
      width: 36px;
      height: 36px;
    }
  }

  .Chat-window-header {
    gap: 8px;
    height: 68px;
    padding: 12px 24px;
    display: flex;
    border-bottom: 1px solid #F7F7F7;
    align-items: center;
    background-color: white;
    z-index: 999;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    @media (min-width: $mobile-width-cutoff) {
      position: sticky;
      top: 0px;
    }
  
    .Chat-window-header-text {
      margin-left: 4px;
    }
  
    .Chat-window-header-title {
      font-weight: 700;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .Chat-window-header-subtitle {
      font-weight: 500;
      font-size: 13px;
      color: $text-secondary-color;
    }
  
    .Chat-window-header-image {
      object-fit: cover;
      height: 32px;
      width: 32px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.Mobile-loader {
  position: relative;
  top: 50%;
}

.Chat-window-list {
  flex-grow: 1;
  flex-basis: auto;
  overflow: auto;
}

.Chat-window-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
