.ChannelChat {
  &--mobile {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  &--desktop {
    display: flex;
    height: 100%;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;

    &__chat-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
