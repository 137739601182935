@import "../../../../variables";

.PlanTime {
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;

  &__icon {
    font-size: 1.5rem;
    color: $blueberry-550;
  }

  &__text {
    margin-left: 12px;
  }
}
