@import "../../../../../variables";

.EditFavoriteSpot {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  &__add-button {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  &__plus-icon {
    font-size:24px;
  }
  &__input {
    width: 345px;
  }
  &__minus-button {
    height: 40px;
    width: 40px;
    padding: 0;
    &__icon {
      font-size: 24px;
    }
  }
  &__list {
    position: absolute;
    z-index: 1;
    border-radius: 0.75rem;
    padding: 7px;
    background-color: $white;
    border: 1px solid $gray-200;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
    list-style: none;

    cursor: pointer;
    &__item {
      max-width: 345px;
      padding: 0.8rem 1rem;
    }
  }
  &__loading {
    border-radius: 0.75rem;
    z-index: 1;
    position: absolute;
    width: 345px;

    &__item {
      padding: 0;
    }
  }
}
