@import "../../../variables";

.ChannelRsvpButtons {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &__container {
    align-items: flex-start;
	  align-self: stretch;
	  display: flex;
	  gap: 0.75rem;
	  justify-content: center;
	  padding: 0 1.5rem;
    flex-wrap: wrap;

    &__button {
      background-color: $blueberry-550;
      color: $white;
      font-size: 0.8125rem;
      max-width: 8rem;
      height: 36px;
      padding: 0 16px;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      flex-grow: 1;
      &:hover {
        background-color: $blueberry-750;
      }
      &--selected {
        background-color: $bright-pink;
      }
      &__icon {
        font-size: 1.25rem;
      }
    }
  }
}
