@import "../../../variables";

.ChannelDetail {
  margin-top: 1.5rem;

  &__see-more-button {
    cursor: pointer;
    color: $blueberry-550;
    text-decoration: underline;
  }

  &__container {
    color: $text-secondary-color;
    white-space: pre-wrap; // Preserves line breaks and white space

    &--collapsed {
      color: $text-secondary-color;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-height: 5rem;
      white-space: pre-wrap; // Ensures line breaks are preserved even in collapsed state
    }
  }
}
