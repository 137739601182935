@import "../../variables";

.Error-container {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
}

.Error-title {
  margin: 28px 0 16px 0;
  font-size: 48px;
  color: $blueberry-550;
}

.Error-description {
  font-size: 24px;
  color: $text-secondary-color;
  text-align: center;
}

.Error-contact-email-anchor {
  color: $blueberry-550;
}

.Error-face-image {
  height: 40px;
}
