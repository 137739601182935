@import "../../../../variables";
@import "../../../../pie-design-system";

.ManageChannelMembers {
  &__tabs {
    &__item {
      cursor: pointer;
      border-radius: .125rem .125rem 0 0;
      &:hover {
        background-color: $blueberry-50;
        animation-name: tab-hover-animation;
        animation-duration: 400ms;
      }
      &__text {
        font-weight: 600;
        color: $blueberry-750;
      }
      &__bottom-border {
        animation-name: bottom-border-animation;
        animation-duration: 800ms;
        height: 4px;
        border-radius: 2px;
        background-color: $blueberry-750;
      }
    }

  }
  &__user {
    &__image {
      object-fit: cover;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
    }
    &__dropdown {
      .dropdown-toggle {
        @extend .Button-secondary;
        @extend .Button-medium;
      }
      .dropdown-menu {
        background-color: $white;
        border-radius: 0.5rem;
        border: 1px solid $gray-200;
      }
      .dropdown-item {
        font-size: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:hover {
          background-color: $blueberry-50;
        }
      }
    }

  }
  &__loading-skeleton {
    width: 100%;
    height: 2rem;
    span {
      border-radius: 1.5rem;
    }
  }
}
