@import "../../variables";
@import "../../spacing";

.ChannelNavButtons {
  border-bottom: 1px solid $gray-50;
  justify-content: space-between;

  &__back-button {
    @media (min-width: $mobile-width-cutoff) {
      margin-left: $sp-20;
    }
    margin-left: 1.5rem;
  }

  &__buttons-right {
    @media (min-width: $mobile-width-cutoff) {
      margin-right: $sp-20;
    }
    margin-right: 1.5rem;
    &__settings-button {
      height: 32px;
      width: 32px;
      border-radius: 16px;
      &__icon {
        font-size: 20px;
      }
    }

    &__delete-button {
      height: 32px;
      width: 32px;
      border-radius: 16px;
      &__icon {
        font-size: 20px;
      }
    }
  }
}
