@import "../../../variables";
@import "../../../spacing";

.MutualFriends {
  &__list {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-block-start: $sp-3;
    margin-block-end: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

    &__item {
      &__image {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    @media (max-width: $mobile-width-cutoff) {
      display: flex;
      overflow: scroll;
    }
  }
}
