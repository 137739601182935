@import '../../variables';

.Terms-update {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Terms-update-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 100px 24px 60px 24px;
  text-align: center;

  @media (min-width: $mobile-width-cutoff) {
    padding: 80px;
    background-color: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-shadow: 0px 4px 20px rgba(30, 0, 114, 0.05);
    border-radius: 16px;
    max-width: $mobile-width-cutoff;
  }

  .Terms-update-header {
    margin: 12px 0;
    line-height: 38,4px;
  }

  .Terms-update-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .Terms-update-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    color: $blueberry-550;
    cursor: pointer;
    margin-top: 12px;

    .Terms-update-link-icon {
      transform: rotate(-90deg);
    }

    &:hover {
      color: $blueberry-750;
    }
  }

  .Terms-update-button-agree {
    height: 48px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    background-color: $blueberry-550;
    color: $buttercream;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 12px;

    &:hover {
      background-color: $blueberry-750;
    }
  }
}
