@import "../../variables";

.PublicPlans {
  display: flex;
  flex-direction: row;
  flex: 0 0 75vw;
  padding: 1.25rem;
  background: rgba($white, 0.80);
  border-radius: 24px;
  max-width: 270px;

  &__container {
    display: flex;
    flex: 1 1 80%;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    flex: 1;
  }

  &__creator {
    color: $bright-pink;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2;

    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: $bright-pink;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  &__title {
    color: $gray-900;
    display: -webkit-box;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__details {
    color: $blueberry-550;
    display: -webkit-box;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__aside {
    flex: 1 1 20%;
    display: block;

    &__box {
      background-color: $blueberry-250;
      height: 0;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative;
      width: 100%;
      border-radius: 8px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
}
