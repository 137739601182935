@import "../../variables.scss";

.Auth-card {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 2rem 0;
  @media (min-width: $mobile-width-cutoff) {
    min-width: 800px;
  }
}

.Enhanced-Auth-card {
  height: 120vh;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 2rem 0;
  background-color: $blueberry-450;
  background-image: linear-gradient(58.45deg, rgba(77, 48, 255, 0.48) 9.54%, rgba(37, 0, 140, 0.48) 80.66%),
  radial-gradient(142.54% 116.97% at 78.28% 35.1%, #4D30FF 0%, $blueberry-650 33.33%, $blueberry-450 66.67%, $blueberry-650 100%);

  @media (min-width: $mobile-width-cutoff) {
    min-width: 800px;
  }
}

.Auth-wrapper {
  max-width: 400px;
  width: 100%;
}

.SignUpOrLogIn {
  gap: 12px;
  align-items: center;

  &__pie-logo {
    height: 36px;
    width: 36px;
  }

  &__header {
    margin: 12px 0;
    text-align: center;
  }
}
