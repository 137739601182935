@import "../../../variables";

.ChannelPlace {
  display: flex;
  flex-direction: column;
  &__link {
    display: flex;
    align-items: center;
    color: $text-primary-color;
    margin-top: 0.75rem;

    &__icon {
      font-size: 1.5rem;
      color: $blueberry-550;
    }

    &__text {
      margin-left: 12px;

      &__address {
        font-weight: 400;
        margin: 0;
        font-size: 16px;
      }
    }

    &:hover {
      color: $blueberry-550;
    }
  }

  &--no-location {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    &__icon {
      font-size: 1.5rem;
      color: $blueberry-550;
    }
  }
}
