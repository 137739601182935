@import "../../../variables";
@import "../../../spacing";

.AddInfo {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $sp-3;
  width: 166.5px;
  height: 87px;
  border-radius: 24px;

  &__icon {
    line-height: 1.75rem;
    font-size: 1.75rem;
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
  }
}
