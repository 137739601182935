@import "../../variables";

.ProfileMenu {
  &__button {
    width: 68px;
    height: 36px;
    border-radius: 36px;
    background-color: $blueberry-50;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      cursor: pointer;
      background-color: $blueberry-150;
    }

    &__badge {
      position: relative;
      top: -12px;
      right: 12px;
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 8px;
      background-color: $bright-pink;
    }

    &__icon {
      position: relative;
      right: 12px;
    }

    &__picture {
      object-fit: cover;
      height: 36px;
      width: 36px;
      min-width: 36px;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  &__menu {
    position: absolute;
    min-width: 150px;
    top: 74px;
    right: 80px;
    padding: 7px;
    background-color: $white;
    color: $blueberry-550;
    border: 1px solid $gray-200;
    -webkit-transition: opacity 0.3s, visibility 0s linear 0.3s;
    -o-transition: opacity 0.3s, visibility 0s linear 0.3s;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
    list-style: none;
    border-radius: 16px;
    z-index: 999;

    &__badge {
      background-color: $bright-pink;
      color: $white;
      margin-left: 4px;
      border-radius: 50%;
      font-size: 12px;
      line-height: 12px;
      height: 16px;
      width: 16px;
    }

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $mobile-width-cutoff) {
      top: 47px;
      right: 20px;
      background-color: $gray-30;
    }

    > * {
      padding: 10px;
      width: 100%;
      font-weight: 600;

      &:hover {
        color: $bright-pink;
      }
    }
  }
}
