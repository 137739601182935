@import "../../../variables";

.AuthName {
  &__header-text {
    text-align: center;
  }

  &__header-icon {
    margin-bottom: 8px;
    text-align: center;
  }

  &__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    margin: 24px 0;

    &__inputs-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      gap: 12px;

      &__input {
        height: 52px;
        border: none;
        border-radius: 16px;
        background-color: $gray-30;
        padding: 0 12px;
        font-size: 18px;
        font-family: $font-family;
      }
    }
  }
}
