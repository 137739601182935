@import "../../variables";

.EnhancedAuth{
  color: #FAFDF0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 0.5rem;
  width: 100%;

  h1 {
    color: #FAFDF0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 24px;
  }

  &__recaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
    height: 100%;
    width: 100%;
  }

  &__header {
    flex: 0;
  }

  &__main {
    flex: 1;
  }

  &__footer {
    flex: 0;
  }

  &__rock_on {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    text-transform: lowercase;
  }

  &__rsvp {
    color: #FAFDF0;
    padding-top: 24px;
    padding-bottom: 10px;
  }

  &__additional_info {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px 16px 16px 16px;
    background: #DFF995;
    color: #050442;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__additional_info_header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__form {
    width: 100%;  

    &__error {
      color: $white;
      text-align: center;
      padding: 0.25em;
      margin-bottom: 1.5em;
    }

    &__inputs-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      gap: 12px;

      &__input {
        height: 52px;
        border: none;
        border-radius: 16px;
        background-color: $gray-30;
        padding: 0 12px;
        font-size: 18px;
        font-family: $font-family;
      }
    }

    &__input-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 24px;

      .PhoneInputCountry {
        gap:0px;
        width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-30;
        border-radius: 16px;
      }

      .PhoneInputCountryIcon {
        width: 20px;
        height: 14px;
      }

      .PhoneInputCountryIcon--border {
        background-color: white;
        box-shadow: none;
      }

      .PhoneInputInput {
        font-family: $font-family;
        flex-grow: 1;
        border: none;
        padding: 0 12px;
        font-size: 18px;
        border-radius: 16px;
        height: 52px;
        background-color: $gray-30;
      }
    }

    &__file-input-label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 158px;
      height: 158px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      background-color: rgb(207, 201, 227);
      background-image: url('/assets/svg/EmptyState.svg');
      background-repeat: no-repeat;
      background-position: center;

      &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    &__file-profile-image {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 58px;
      bottom: 50px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      &__pencil {
        background-repeat: no-repeat;
        background-position: center;
        background-color: $blueberry-550;
        background-image: url('/assets/svg/Pencil.svg');
        width: 50px;
        height: 50px;
      }

      &__camera {
        background-repeat: no-repeat;
        background-position: center;
        background-color: $bright-pink;
        background-image: url('/assets/svg/CameraAdd.svg');
        width: 50px;
        height: 50px;
      }
    }

    &__pic {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &__pic-preview {
      object-fit: cover;
    }

    &__secondary-btns-wrapper {
      margin-top: 24px;
      width: 100%;
      justify-content: center;
      display: flex;
      gap: 24px;
    }

    &__verify-btn {
      margin-top: 24px;
      width: 100%;
    }

    &__error-text {
      text-align: center;
    }

    // override <ReactInputVerificationCode /> styles
    .ReactInputVerificationCode {
      &__container {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: space-around;
      }
      &__item {
        color: rgba(0, 0, 0, 1);
        height: 56px !important;
        width: 48px !important;
        border-radius: 16px !important;
        border: none !important;
        background-color: $gray-30 !important;
        font-size: 24px !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__input {
        // UI issue with horizontal scroll when this is displayed,
        //  but its needed to correctly input code
        top: 0px !important;
        left: 0px !important;
        height: 0px !important;
        width: 0px !important;
      }
    }

    &__terms {
      text-align: center;
      font-size: 13px;
      line-height: 19.5px;
      padding: 0 20px;
      margin-top: 16px;
      color: #FFF;
      &__link {
        color: #FFF;
        text-decoration: underline !important;
        &:hover {
          color: $blueberry-750;
        }
      }
    }
  }

  &__browser-warning {
    background-color: $bright-pink-fifth-opacity;
    color: $bright-pink;
    padding: 16px;
    margin: 22px 0;
    border: 1px solid $bright-pink-half-opacity;
    border-radius: 15px;
    text-align: center;
    font-weight: 600;

    a {
      display: inline-block;
      margin-top: 8px;
      color: $bright-pink;
      text-decoration: underline;

      &:hover {
        color: $blueberry-550;
      }
    }
  }
}
