@import "../../variables";

.MorePlans {
  width: 100%;
  align-self: center;
  &__content-container {
    padding: 3rem 1rem;
    @media (min-width: $mobile-width-cutoff) {
      padding: 5rem;
    }
  }
  &__see-more-btn {
    margin-top: 1.5rem;
    align-self: center;
    min-width: 10rem;
  }
}
