@import "../../../variables";

.PlanListEmpty {
  padding: 5rem 0;
  align-self: center;
  @media (max-width: $mobile-width-cutoff) {
    margin-top: 1.5rem;
  }
  @media (min-width: $mobile-width-cutoff) {
    min-width: 800px;
  }
  &__image {
    height: 72px;
    width: 72px;
  }
  &__text {
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 500;
    color: $text-secondary-color;
  }
}
