@import "../../../variables";

.ChannelInviteFriendsButton {
  &__button {
    min-width: 176px;
    &__icon {
      font-size: 24px;
      padding-right: 8px;
    }
    &__loader {
      margin-right: 0.5rem;
      height: 1rem;
      width: 1rem;
    }
  }
}
