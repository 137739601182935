@import "../../variables";

.Custom-message-input {
  align-items: center;
  background-color: $white;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-top: 1px solid $gray-50;
  bottom: 0;
  display: flex;
  gap: .75rem;
  justify-content: space-between;
  padding: .75rem 1.5rem;
  position: sticky;
  width: 100%;
}

.Custom-message-input-textarea-wrapper {
  align-items: center;
  display: flex;
  gap: 0px;
  flex-grow: 1;
}


.Custom-message-input-attachment-button,
.Custom-message-input-button {
  background-color: $blueberry-50;
  color: $blueberry-550;
  border: none;
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Custom-message-input-button {
  background-color: $blueberry-550;
  width: 36px;
  height: 36px;
}

.Custom-message-input-hide {
  display: none;
}

.rta__textarea {
  align-items: center;
  border: 1px solid $gray-50;
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem 3rem .5rem .75rem;
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (max-width: $mobile-width-cutoff) {
    padding-right: .75rem;
  }

  @media (max-width: $mobile-width-cutoff) {
    padding-right: 20px;
  }
}

.Custom-message-input-emoji-wrapper {
  width: 100%;
}

.Custom-message-input-emojiselect {
  position: absolute;
  right: 80px;
  bottom: calc(50% - 15px);
  cursor: pointer;
}

.Custom-message-input-emojiselect-img {
  height: 24px;
}

.rfu-image-input {
  display: none;
}

.file-input-preview {
  width: 200px;
}

.rfu-file-upload-button {
  background-color: $blueberry-50;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: $button-secondary-bg-color-hover;
    transition: ease-out background-color 250ms;
  }
}

.rfu-file-input {
  display: none;
}

.str-chat__attachment-preview-list {
  flex: 0;
  padding: 0;
  margin: 0 .5rem;
}

.str-chat__attachment-preview-image {
  box-shadow: 7px 1px 6px rgba(0, 0, 0, 0.1);
}

.str-chat__emoji-picker-container{
  position: absolute;
  bottom: 62px;
  right: 6px;
}

.str-chat__attachment-preview-list .str-chat__attachment-preview-image,
.str-chat__attachment-preview-list .str-chat__attachment-preview-image .str-chat__attachment-preview-thumbnail {
  max-height: 2.5rem;
  max-width: 2.5rem;
}

.str-chat__attachment-preview-list .str-chat__attachment-preview-delete {
  inset-inline-end: 0;
  top: 0;
}

.str-chat__attachment-preview-list .str-chat__attachment-preview-delete svg {
  background-color: rgba(0,0,0,0.5);
}
