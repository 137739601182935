@import '../../variables';

.BottomDrawer {
  border-radius: 32px 32px 0 0;
  padding-top: 24px;
  height: 100% !important;

  &__header {
    padding: 24px 24px 0 24px;
    justify-content: flex-end;
    @media (max-width: $mobile-width-cutoff) {
      padding-top: 12px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px 24px 24px;
    background-color: white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;

  }
}

.FillHeight {
  height: 100% !important;
}

.AutoHeight {
  height: auto !important;
}

.EnhancedBottomDrawer {
  background-color: $blueberry-450;
  background-image: linear-gradient(58.45deg, rgba(77, 48, 255, 0.48) 9.54%, rgba(37, 0, 140, 0.48) 80.66%),
  radial-gradient(142.54% 116.97% at 78.28% 35.1%, #4D30FF 0%, $blueberry-650 33.33%, $blueberry-450 66.67%, $blueberry-650 100%);
  height: 90% !important;
  border-radius: 24px 24px 0 0;

  & &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  &__header {
    @media (max-width: $mobile-width-cutoff) {
      padding-top: 12px;
    }
  }

  &__Button {
    color: $white;
    margin-left: auto;
    font-size: 16px;
  }
}
