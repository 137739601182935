@import "../../variables";
@import "../../spacing";

.PlanEdit {
  width: 100%;
  align-self: center;
  margin-bottom: 5rem;
  &__content {
    &__container {
      margin-bottom: 3rem;
      padding: $sp-6 1.5rem 0 1.5rem;

      &__title {
        text-align: center;
      }

      &__picture {
        flex-grow: 1;
        max-width: fit-content;

        @media (max-width: $mobile-width-cutoff) {
          align-items: center;
        }

        &__image {
          object-fit: cover;
          height: 196px;
          width: 196px;
          border-radius: $sp-3;

          @media (max-width: $mobile-width-cutoff) {
            height: 80px;
            width: 80px;
          }
        }
      }

      &__form {
        flex-grow: 2;
        margin-right: $sp-10;

        @media (max-width: $mobile-width-cutoff) {
          margin-right: 0;
        }

        .Input__input {
          font-family: $font-family;
          text-overflow: ellipsis;
        }

        &__time-and-tz-container {
          width: 100%;
          display: grid;
          grid-gap: 0.75rem;
          grid-template-columns: 1fr 1fr;
          @media (max-width: 375px) {
            grid-template-columns: 1fr;
          }
        }

        &__time-input {
          width: 100%;
        }

        &__timezone {
          position: relative;

          &__select {
            position: relative;
            padding: 1.625rem 1.625rem 0.625rem 0.75rem;
            width: 100%;
            min-width: 5rem;
            font-family: $font-family;
            font-size: 1rem;
            line-height: 1.5rem;
            background: $white;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-overflow: ellipsis;
          }

          &__select-label {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 1rem .75rem;
            pointer-events: none;
            border: 1px solid transparent;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out, transform .1s ease-in-out;
            color: $text-tertiary-color;
            font-size: 1rem;
            opacity: .65;
            transform: scale(.85) translateY(-.5rem) translateX(.15rem);
          }

          &__select-caret {
            position: absolute;
            top: calc(50% - 8px);
            right: 0.5rem;
          }
        }

        &__where_hide_button_wrapper {
          // centered with input, but not centered with input results when they're open
          margin-top: 11px;
        }
      }

      @media (min-width: $mobile-width-cutoff) {
        padding: $sp-20 5rem 0 5rem;
      }
    }
    &__sticky-container {
      position: sticky;
      bottom: 0px;
    }
  }
  @media (max-width: $mobile-width-cutoff) {
    margin-bottom: 0px;
  }
}

.planInfo {
  text-align: end;
  font-size: 0.875rem;
}
.planInfoOver {
    color: $red;
    font-weight: 800;
}
