@import '../../variables';

.App-header {
  background-color: $blueberry-40;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-header-pie-logo-wordmark {
  cursor: pointer;
  height: 30px;
}

.App-header-download-link-profile-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.App-header-btn {
  align-items: center;
  background: $blueberry-550;
  border-radius: 48px;
  color: $buttercream;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  padding: 8px 16px;

  &:hover {
    background: $blueberry-750;
  }
}

.Log-in-btn {
  font-size: 16px;
  height: 36px;
}

.App-header-download-link {
  font-size: 14px;
}

.App-header-download-link-image {
  margin: 0 4px 0 0;
}

.App-header-chat {
  font-size: 2rem;
  color: $blueberry-550;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;
}

.App-header-chat-badge {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: $bright-pink;
  line-height: 1rem;
  font-size: 12px;
  color: $white;
  text-align: center;
}

@media (min-width: $mobile-width-cutoff) {
  .App-header {
    padding: 2.5rem 5rem 0 5rem;
    margin-bottom: 1.5rem;
  }

  .App-header-download-link {
    padding: 8px 16px;
    font-size: 16px;
  }

  .App-header-download-link-image {
    margin: 0 8px 0 0;
  }
}
