@import "../../../variables";
@import "../../../spacing";

.Profile {
  width: 100%;
  align-self: center;
  margin-bottom: 5rem;
  &__content {
    &__container {
      margin-bottom: 4.5rem;
      padding: $sp-6 1.5rem 0 1.5rem;
      @media (min-width: $mobile-width-cutoff) {
        padding: $sp-6 5rem 0 5rem;
      }
    }

    &__add-interests {
      padding: 20px;
      background-color: $blueberry-40;
      border-radius: 20px;

      &__title {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;

      }
    }
  }
  @media (max-width: $mobile-width-cutoff) {
    margin-bottom: 0px;
  }
}
