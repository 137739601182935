@import "../../variables";

.ChatList {
  margin-bottom: 5rem;

  @media (max-width: $mobile-width-cutoff) {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    background-color: $white;
    border-radius: inherit;

    @media (max-width: $mobile-width-cutoff) {
      width: 100%;
    }

    &__channels-list {
      border-right: 1px solid #EFEEEE;
      min-width: 20rem; 

      @media (max-width: $mobile-width-cutoff) {
        width: 100%;
        &--isHidden {
          display: none;
        }
      }

      @media (min-width: $mobile-width-cutoff) {
        max-width: 20rem;
        overflow: hidden;
        border-bottom-left-radius: inherit;
      }
    }

    &__chat-view {
      border-radius: inherit;
      @media (max-width: $mobile-width-cutoff) {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        &--isHidden {
          display: none;
        }
      }

      @media (min-width: $mobile-width-cutoff) {
        width: 100%;
      }
    }

    &__header {
      display: flex;
      align-items: center;

      @media (max-width: $mobile-width-cutoff) {
        padding: 0 1.5rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #EFEEEE;
        position: sticky;
        top: 0;
        z-index: 999;
        background: $white;

        .str-chat__avatar {
          display: none;
        }
      }

      &__back-button {
        height: 2.25rem;
        width: 2.25rem;
      }
    }
  }

  .str-chat__header-livestream-left.str-chat__channel-header-end {
    row-gap: 0;
  }

  .str-chat__header-livestream-left--title.str-chat__channel-header-title {
    font-weight: 600;
  }

  .str-chat__channel-preview-messenger--right.str-chat__channel-preview-end {
    row-gap: 0;
  }

  .str-chat__channel-header {
    border-radius: 1.5rem;
    column-gap: .75rem;
    padding: 0.75rem 1.5rem;
  }

  .str-chat__channel-preview {
    background-color: $white;
    padding: 0.75rem 1.5rem;
  }

  .str-chat__date-separator {
    display: none;
  }
  .str-chat__message--deleted {
    display: none;
  }
  .str-chat__message-reactions {
    display: none;
  }

  .str-chat {
    border-radius: inherit;
    border-right: none;
  }

  .str-chat__load-more-button__button {
    background-color: $button-secondary-bg-color;
    border-radius: 2rem;
    color: $button-secondary-text-color;
    font-size: .875rem;
    font-weight: 600;
    height: 2.25rem;
    text-transform: capitalize;
    idth: inherit;

    &:hover {
      background-color: $button-secondary-bg-color-hover;
      transition: ease-out background-color 250ms;
    }

    &:focus {
      background-color: inherit;
    }
  }

  .str-chat__message .str-chat__message-bubble {
    background-color: $gray-30;
    color: $gray-800;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .str-chat__message.str-chat__message--me .str-chat__message-bubble {
    color: $black;
  }

  .str-chat__message-reactions-container .str-chat__message-reactions .str-chat__message-reaction.str-chat__message-reaction-own {
    background-color: $blueberry-550;
    color: $buttercream;
  }

  .str-chat__channel-list .str-chat__channel-list-messenger .str-chat__channel-list-messenger__main .str-chat__channel-list-empty {
    height: 200px;
  }

  .str-chat__message .str-chat__message-mention {
    color: inherit;
    font-weight: 600;
  }
  
  .str-chat__main-panel {
    height: 100%;
  }

  .str-chat__main-panel-inner {
    align-items: flex-start;
    min-height: 450px;
  }

  .str-chat__date-separator {
    display: none;
  }
  .str-chat__message {
    row-gap: 0.0625rem;
    column-gap: 0.25rem;
  }
  .str-chat__li {
    list-style-type: none;
  }
  ul {
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .str-chat__message--system {
    margin-bottom: 5px;
  }

  .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular).str-chat__message-actions-box--mine {
    inset-inline-start: -360%;
  }

  .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular):not(.str-chat__message-actions-box--mine) {
    inset-inline-end: -150%;
  }

  .str-chat__li.str-chat__li--single, .str-chat__li.str-chat__li--top {
    margin-top: 1rem;
  }

  .str-chat__message-simple-status {
    display: none;
  }

  .str-chat__message-status {
    display: none;
  }

  .str-chat__list-notifications {
    display: none;
  }

  .str-chat__message-input {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .str-chat__message-reactions {
    display: none; // will be remove once we start using reactions
  }

  .str-chat__modal {
    z-index: 1000;
  }

  .message-input {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: $mobile-width-cutoff) {
      position: sticky;
      bottom: 0px;
    }
  }

  .Custom-message-input {
    position: sticky;
    bottom: 0px;
  }

  .str-chat__channel-preview-messenger--active {
    background-color: $blueberry-40;

    @media (max-width: $mobile-width-cutoff) {
      background-color: transparent;
    }
  }

  .str-chat__channel-preview-messenger:hover .str-chat__channel-preview-messenger--name {
    color: $blueberry-750;
  }

  .str-chat__channel-preview-messenger:hover {
    background-color: $blueberry-50;
    transition: ease-out background-color 250ms;
  }

  .str-chat__channel-preview-messenger--name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: $blueberry-550;
  }

  .str-chat__channel-preview-messenger--last-message {
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-weight: 500;
    color: $gray-600;
  }

  .str-chat__channel-preview-unread-badge {
    background: $bright-pink !important;
  }

  @media (max-width: $mobile-width-cutoff) {
    .str-chat__channel-list-messenger-react__main {
      max-height: 680px;
    }

    .str-chat__channel-list {
      position: absolute;
      bottom: 0;
      overflow-y: hidden;
      height: calc(100% - 138px);
    }

    .str-chat__channel-list-react .str-chat__channel-list-messenger-react {
      padding-bottom: 0
    }
  }
}
