@import "../../../variables";

.VerificationCode {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__header-icon {
    text-align: center;
    margin-bottom: 8px;
  }

  &__header-text {
    text-align: center;
    margin-bottom: 24px;
  }

  &__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 200px;

    &__footer {
      width: 100%;

      &__secondary-btns-wrapper {
        margin-top: 24px;
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 24px;
      }
      &__verify-btn {
        margin-top: 24px;
        width: 100%;
      }
      &__error-text {
        margin-top: 8px;
        text-align: center;
      }
    }

    // override <ReactInputVerificationCode /> styles
    .ReactInputVerificationCode {
      &__container {
        width: 100% !important;
        gap: 4px;
      }
      &__item {
        height: 56px !important;
        width: 48px !important;
        border-radius: 16px !important;
        border: none !important;
        background-color: $gray-30 !important;
        font-size: 24px !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__input {
        // UI issue with horizontal scroll when this is displayed,
        //  but its needed to correctly input code
        top: 0px !important;
        left: 0px !important;
        height: 0px !important;
        width: 0px !important;
      }
    }
  }

  &__terms {
    font-size: 13px;
    line-height: 19.5px;
    padding: 0 20px;
    margin-top: 16px;
    color: $text-secondary-color;
    &__link {
      color: $blueberry-550;
      text-decoration: underline !important;
      &:hover {
        color: $blueberry-750;
      }
    }
  }
}
