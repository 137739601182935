@import "variables";
@import "spacing";

.Button-large {
  height: 48px;
  border-radius: 24px;
  padding: 0 24px;
  font-size: 16px;
}

.Button-medium {
  height: 36px;
  border-radius: 18px;
  padding: 0 18px;
  font-size: 14px;
}

.Button-small {
  height: 32px;
  border-radius: 16px;
  padding: 0 16px;
  font-size: 14px;
}

.Button-primary {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  font-weight: 600;
  &:hover {
    background-color: $button-primary-bg-color-hover;
  }
  &--disabled {
    background-color: $button-primary-bg-color-disabled;
    color: $button-primary-text-color-disabled;
    cursor: not-allowed;
    &:hover {
      background-color: $button-primary-bg-color-disabled;
    }
  }
}

.EnhancedButton-primary {
  background-color: $enhanced-button-primary-background-color;
  color: $enhanced-button-primary-text-color;
  text-overflow: ellipsis;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  &:hover {
    background-color: $enhanced-button-primary-bg-color-hover;
  }
  &--disabled {
    background-color: $button-primary-bg-color-disabled;
    color: $button-primary-text-color-disabled;
    cursor: not-allowed;
    &:hover {
      background-color: $button-primary-bg-color-disabled;
    }
  }
}

.Button-secondary {
  background-color: $button-secondary-bg-color;
  color: $button-secondary-text-color;
  font-weight: 600;
  &:hover {
    background-color: $button-secondary-bg-color-hover;
  }
  &--disabled {
    background-color: $button-secondary-bg-color-disabled;
    color: $button-secondary-text-color-disabled;
    cursor: not-allowed;
    height: 36px;
    padding: 0 16px;
    border-radius: 18px;
    font-weight: 600;
    font-size: 14px;
  }
}

.Primary-text {
	color: $text-primary-color;
}

.Secondary-text {
	color: $text-secondary-color;
}

.Tertiary-text {
	color: $text-tertiary-color;
}

.Text-centered {
  text-align: center;
}

.Header-1 {
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.4rem;
}

.Header-2 {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.8rem;
}

.Header-3 {
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1.5rem;
}

.Header-4 {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5rem;
}

.Header-5 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
}

.Gray-text {
  color: $text-secondary-color;
}

.Blueberry-text {
  color: $blueberry-550;
}

.Subtitle {
	color: $text-secondary-color;
	font-size: 0.8125rem;
	font-weight: 600;
	line-height: 1.25rem;
}

.Caption {
	color: $text-secondary-color;
	font-size: 0.8125rem;
	font-weight: 500;
	line-height: 1.25rem;
}

.Pink-text {
  color: $bright-pink;
}

.Small-font {
  font-size: 0.8125rem;
}

.Circular-image {
  object-fit: cover;
  border-radius: 50%;
}

hr {
  background-color: $horizontal-rule-color;
  border: 0 none;
  clear: both;
  color: $horizontal-rule-color;
  height: 0.0625rem;
  margin: 1.5rem 0;
}


input, textarea, select {
  border: 0.0625rem solid $blueberry-250 !important;
  border-radius: 0.75rem !important;
  color: $text-primary-color !important;
  caret-color: $bright-pink !important;

  &:focus {
    box-shadow: none !important;
  }

  &:required {
    border: 1px solid $red !important;
  }
}

textarea {
  padding: $sp-6 $sp-4;
}

.Bar {
  background: $white;
}

.Bar-top {
  box-shadow: 0rem 0.125rem .25rem 0rem rgba(0, 0, 0, 0.08);
  border-bottom: 0.0625rem solid $gray-70;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  top: 0;
  position: sticky;
  z-index: 50;
}

.Bar-bottom {
  border-top: 0.0625rem solid $gray-70;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  position: sticky;
  z-index: 50;
}

.Ellipses {
  text-overflow: ellipsis;
}

.Min-width-0 {
  min-width: 0;
}
