@import "../../../variables";

.ChannelInviteFriendsCallout {
  background-color: $coconut-green;
  border-radius: 1.5rem;
  &__icon {
    height: 36px;
    width: 36px;
  }
  &__share {
    max-width: 100%;
    &__link {
      height: 36px;
      flex: 1 1 0;
      min-width: 0;
      background-color: $white;
      border-radius: 32px;
      &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &__button {
      min-width: 120px;
      &__loader {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
