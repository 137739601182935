@import "../../variables";
@import "../../spacing";

.ButtonsCarousel {
  padding: $sp-3 80px;
  border-top: 1px solid $gray-70;
  position: sticky;
  bottom: 60px;
  background: $white;
  overflow: scroll;
  z-index: 1;

  @media (max-width: $mobile-width-cutoff) {
    padding: $sp-3 $sp-6;
  }

  @media (min-width: $mobile-width-cutoff) {
    overflow: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}
