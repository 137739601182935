@import "../../../variables";

.ChooseImage {
  max-width: 100%;
  @media (min-width: $mobile-width-cutoff) {
    padding: 5rem;
  }

  &__column-1 {
    @media (max-width: $mobile-width-cutoff) {
      padding: 1.5rem;
    }
  }

  &__column-2 {
    position: sticky;
    top: 0;
    right: 0;
    margin-bottom: 12px;
  }

  &__preview-image {
    width: 196px;
    height: 196px;
    object-fit: cover;
    border-radius: 1.5rem;

    @media (max-width: $mobile-width-cutoff) {
      width: 80px;
      height: 80px;
      border-radius: 1rem;
    }
  }

  &__images-grid {
    max-width: 100%;
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
    &__loading-skeleton {
      width: 196px;
      height: 196px;
      border-radius: 1.5rem;
      @media (max-width: $mobile-width-cutoff) {
        margin: 0.375rem;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 1rem;
      }
    }
    &__image {
      cursor: pointer;
      width: 196px;
      height: 196px;
      border-radius: 1.5rem;

      @media (max-width: $mobile-width-cutoff) {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }

      img {
        width: 196px;
        height: 196px;
        border-radius: 1.5rem;

        @media (max-width: $mobile-width-cutoff) {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }

      &:hover, &:focus {
        outline: 0.25rem solid $bright-pink;
      }

      &--selected {
        position:relative;
      }

      &--selected::before {
        border-radius: 1.5rem;

        content:"";
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:10;
        background: rgba(227,58,255, 0.75);

        @media (max-width: $mobile-width-cutoff) {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }
      &__icon {
        position: absolute;
        background-color: $white;
        color: $bright-pink;
        height: 2rem;
        width: 2rem;
        font-size: 32px;
        border-radius: 50%;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        z-index: 20;
      }
    }
  }

  &__sticky-image-preview {
    position: sticky;
    z-index: 50;
    top: 0;

    @media (max-width: $mobile-width-cutoff) {
      padding-top: 1.5rem;
      width: 100%;
      background: $white;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
    }
  }

  &__sticky-buttons {
    z-index: 50;
    position: sticky;
    bottom: 0;
  }
}
