$blueberry-750: #050442;
$blueberry-650: #170059;
$blueberry-550: #1E0072;
$blueberry-450: #25008C;
$blueberry-250: #9F93C1;
$blueberry-150: #CFC9E3;
$blueberry-100: #E2DFEB;
$blueberry-50: #E9E6F2;
$blueberry-30: #F4F2F8;
$blueberry-40: #F3F2F8;

$gray-900: #1A1B18; // This color use instead of obsolete $dark-grey - primary text color
$gray-800: #353630;
$gray-600: #6A6C60; // This color use instead of obsolete $medium-grey - secondary text color
$gray-400: #9D9F93;
$gray-250: #C2C3BB;
$gray-200: #CECFC9; // This color use instead of obsolete $medium-light-grey
$gray-150: #D6D5D5;
$gray-100: #E5E5E4;
$gray-70: #ECECEA;
$gray-50: #F3F3F1; // This color use instead of obsolete $grey-border
$gray-30: #F8F8F7; // This color use instead of obsolete $light-grey

$buttercream: #FAFDF0;
$bright-pink: #E33AFF;
$bright-pink-half-opacity: #e33aff80;
$bright-pink-fifth-opacity: rgba(227, 58, 255, 0.2);
$bright-pink-tenth-opacity: #e33aff1a;
$white: #FFFFFF;
$black: #000000;
$red: #C41E3A;
$coconut-green: #DFF995;

$font-family: 'Avantt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$mobile-width-cutoff: 800px;

// Design system
$button-primary-bg-color: $blueberry-550;
$button-primary-text-color: $buttercream;
$button-primary-bg-color-hover: $blueberry-750;
$button-primary-bg-color-disabled: $blueberry-250;
$button-primary-text-color-disabled: $buttercream;

$button-secondary-bg-color: $blueberry-50;
$button-secondary-text-color: $blueberry-550;
$button-secondary-bg-color-hover: $blueberry-150;
$button-secondary-bg-color-disabled: $blueberry-40;
$button-secondary-text-color-disabled: $blueberry-250;

$text-primary-color: $gray-900;
$text-secondary-color: $gray-600;
$text-tertiary-color: $gray-400;

$raised-surface-border-color: $gray-50;
$horizontal-rule-color: $gray-100;

// Enhanced [onboarding phase 1 & 2] design system
$enhanced-button-primary-background-color: $bright-pink;
$enhanced-button-primary-text-color: $white;
$enhanced-button-primary-bg-color-hover: rgba($bright-pink, 0.90);

@keyframes bottom-border-animation {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: $blueberry-750;
  }
}

@keyframes tab-hover-animation {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: $blueberry-50;
  }
}
