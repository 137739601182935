@import "../../../variables";

.AuthPicture {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header-icon {
    text-align: center;
    margin-bottom: 8px;
  }

  &__header-text {
    text-align: center;
  }

  &__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 24px;

    &__file-input-label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 158px;
      height: 158px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      background-color: rgba(30, 0, 114, 0.05);
      background-image: url('/assets/svg/camera.svg');
      background-repeat: no-repeat;
      background-position: center;
      &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    &__pic-preview {
      object-fit: cover;
    }
  }
}
