@import "../../variables";

@keyframes box-shadow-animation {
  0%   {box-shadow: none}
  100% {box-shadow: 0px 2px 4px rgba(5, 4, 66, 0.1);}
}


.Plan-card {
  padding: 1.25rem;
  border-radius: 1.25rem;
  border: 1px solid $raised-surface-border-color;
  margin-bottom: 24px;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:hover {
    animation-name: box-shadow-animation;
    animation-duration: 200ms;
    box-shadow: 0px 2px 4px rgba(5, 4, 66, 0.1);
    cursor: pointer;
  }

  &__body {
    display: flex;

    &__columns {
      justify-content: space-between;
      display: flex;
      flex-grow: 1;
      height: 100%;

      &__column-1__row-3 {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &__column-2 {
        display: flex;
        margin: 0 0 0 0.75rem;
        &__image {
          object-fit: cover;
          height: 6rem;
          width: 6rem;
          border-radius: 0.75rem;
        }
      }
    }
  }



  .Plan-card-pills-container {
    .Plan-card-pill {
      border-radius: 13px;
      display: inline-block;
      padding: 4px 8px;
      background-color: $gray-30;
      color: $text-secondary-color;
      border: 1px solid $gray-50;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      display: inline-flex;
      align-items: center;
      gap: 4px;

      &:not(:first-child) {
        margin-left: 6px;
      }

      .Plan-card-pill-icon {
        height: 9px;
      }
    }
  }

  .Plan-card-user-icons-container {
    display: flex;
    margin-top: 12px;
    .Plan-card-user-icon {
      object-fit: cover;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid $white;
      &:not(:last-child) {
        margin-right: -0.5rem;
      }
    }
    .Plan-card-user-icon-extra-count {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: $gray-30;
      border: 2px solid $white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-secondary-color;

      .Plan-card-user-icon-extra-count-text {
        font-size: 12px;
        font-weight: 600;
        margin-left: -2px;
      }
    }
  }

  &__divider {
    margin: 0.5rem 0;
  }

  &__footer {
    &__icon {
      color: $text-secondary-color;
    }
    &__rsvp-status {
      font-size: 0.8125rem;
      font-weight: 600;
    }
  }
}
