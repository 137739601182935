@import "../../../variables";
@import "../../../spacing";

.AnonymUser {
  width: 100%;
  align-self: center;
  margin-bottom: 5rem;

  &__container {
    margin-bottom: 4.5rem;
    padding: $sp-6 1.5rem 0 1.5rem;
    @media (min-width: $mobile-width-cutoff) {
      padding: $sp-6 5rem 0 5rem;
    }

    &__user-detail {
      justify-content: space-between;
      align-items: flex-end;

      &__picture {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        object-fit: cover;
    
        @media (max-width: $mobile-width-cutoff) {
          margin-bottom: $sp-3;
          width: 96px;
          height: 96px;
        }
      }

      @media (max-width: $mobile-width-cutoff) {
        align-items: flex-start;
        flex-direction: column-reverse;
      }
    }

    &__subtitle {
      text-align: center;
    }

    &__button {
      width: 308px;

      @media (max-width: $mobile-width-cutoff) {
        width: 100%;
      }
    }
  }
}
