@import "../../variables";

.Base {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  @media (min-width: $mobile-width-cutoff) {
    background-color: $blueberry-40;
  }
}
