@import "../../../variables";

.ChannelRecentMessages {
  &__message {
    &__image {
      height: 32px;
      width: 32px;
    }
    &__text {
      font-family: Avantt;
      border-radius: 20px 20px 20px 2px;
      background-color: $gray-50;
      padding: 0.75rem 1rem;
    }
  }
}
