@import "../../../variables";

.PlanSettings {
  padding: 1.5rem;
  @media (min-width: $mobile-width-cutoff) {
    padding: 5rem;
  }

  &__option {
    &:not(:first-child) {
      border-top: 1px solid $gray-70;
      padding-top: 24px;
    }
    &__text {
      color: $blueberry-550;
    }
    &__toggle {
      .form-check-input {
        cursor: pointer;
        height: 1.5rem;
        width: 2.5rem;
        border-radius: 16px !important;
        border: none !important;
        background-color: $gray-100;
        background-image: url('/assets/svg/SwitchToggle.svg');

        &:focus {
          background-image: url('/assets/svg/SwitchToggle.svg');
        }

        &:checked {
          background-color: $bright-pink;
          background-image: url('/assets/svg/SwitchToggle.svg');
        }
      }
    }
  }

  &__capacity {
    margin-top: -12px;
    margin-left: 48px;
    &__input {
      max-width: 64px;
      .Input__input {
        height: 14px;
        font-family: $font-family;
      }
    }

  }

  &__sticky-container {

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @media (max-width: $mobile-width-cutoff) {
      position: sticky;
      bottom: 0px;
    }
  }
}
