@import "../../../variables";
@import "../../../spacing";

.FavoriteSpotPill {
  border: 1px solid $gray-100;
  border-radius: 1.5rem;

  &:not(:first-child) {
    margin-top: $sp-3;
  }
  &__icon {
    font-size: 1.5rem;
  }
}
