@import '../../variables';
@import '../../spacing';

.InterestsPill {
  padding: 0px $sp-4;
  height: 2.25rem;
  border: 1px solid $blueberry-550;
  border-radius: 2.25rem;
  background-color: transparent;
  color: $blueberry-550;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  max-width: fit-content;

  &__checkmark {
    margin-right: $sp-1;
    font-size: 1rem;
  }

  &--selected {
    background-color: $bright-pink;
    color: $white; 
    border: none;
  }

  &--disabled {
    background-color: transparent;
    border: 1px solid $gray-100;
    color: $black;
    cursor: auto;
  }
}
