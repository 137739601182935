@import "../../../variables";
@import "../../../spacing";

.EditModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  &__dialog {
    max-height: 70%;
    max-width: 90%;
    display: flex;

    &__content {
      background-color: white;
      padding: 20px;
      border-radius: 16px;

      &__body {
        width: 100%;
        height: 90%;
      }
    }
  }
}
