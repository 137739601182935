@import "../../variables";
@import "../../spacing";

.ConfirmationButtons {
  background: $white;
  z-index: 1;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-top: 0.5px solid $gray-70;

  &__container {
    padding: $sp-3 5rem;
    gap: $sp-3;

    @media (max-width: $mobile-width-cutoff) {
      width: calc(100% - 2 * #{$sp-6});
      padding: $sp-3 $sp-6;
    }

    &__button {
      padding: 0 $sp-6;
      
      @media (max-width: $mobile-width-cutoff) {
        width: 100%;
      }

      &--primary {
        min-width: 118px;
      }
    }
  }
}
