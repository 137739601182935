@import "../../../variables";

.PhoneNumber {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header-icon {
    text-align: center;
    margin-bottom: 8px;
  }

  &__header-text {
    text-align: center;
    margin-bottom: 24px;
  }

  &__form {
    width: 100%;
    min-height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    &__error {
      color: $blueberry-550;
      text-align: center;
    }

    &__input-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      // Override <PhoneInput /> styles
      .PhoneInputCountry {
        gap:0px;
        width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-30;
        border-radius: 16px;
      }

      .PhoneInputCountryIcon {
        width: 20px;
        height: 14px;
      }

      .PhoneInputCountryIcon--border {
        background-color: white;
        box-shadow: none;
      }

      .PhoneInputInput {
        font-family: $font-family;
        flex-grow: 1;
        border: none;
        padding: 0 12px;
        font-size: 18px;
        border-radius: 16px;
        height: 52px;
        background-color: $gray-30;
      }
    }
  }
}
