@import "../../variables";

.GetThePieApp {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__info {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;

    &__title {
      line-height: 1.3;
    }
  }

  ul {
    margin: 1rem 0 0 0;
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  &__image {
    flex: 1 1 40%;
    position: relative;
    overflow: visible;
    overflow-y: clip;

    img {
      border-radius: 10px;
      height: auto;
      left: -15%;
      position: absolute;
      top: 0;
      width: 115%;

    }
  }

  @media (max-width: $mobile-width-cutoff) {
    &__image {
      flex: 1 1 33.33%;

      img {
        top: -5px;
        left: -25%;
        width: 165%;
        max-width: 240px;
      }
    }
  }

  @media (max-width: 600px) {
    &__image {
      img {
        left: 0;
      }
    }
  }
}
