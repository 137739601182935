@import "../../variables";

.InAppBrowserView-root {
  background-color: $blueberry-450;
  background-image: linear-gradient(58.45deg, rgba(77, 48, 255, 0.48) 9.54%, rgba(37, 0, 140, 0.48) 80.66%),
    radial-gradient(142.54% 116.97% at 78.28% 35.1%, #4D30FF 0%, $blueberry-650 33.33%, $blueberry-450 66.67%, $blueberry-650 100%);
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  color: $white;
  text-align: center;
  padding-top: 63px;
  padding-bottom: 63px;
  padding-right: 24px;
  padding-left: 24px;
  overflow-y: hidden;


  h1 {
    color: $white;
    width: 75%;
    white-space: pre-line;
  }

  a {
    background-color: $bright-pink;
    width: fill;
    padding: 14px;
    border-radius: 48px;
    font-weight: bold;
    font-size: 18;
    color: $white;

    :visited,
    :link {
      color: $white
    }
  }
}