@import "../../../variables";

.ChannelDates {
  margin-top: 0.75rem;
  &--no-location {
    margin-bottom: 1.5rem;
    &__icon {
      font-size: 1.5rem;
      color: $blueberry-550;
    }
  }
}
