@import "../../../../variables";

.PlanDate {
  display: flex;
  align-items: center;

  &__icon {
    font-size: 1.5rem;
    color: $blueberry-550;
  }

  &__container {
    display:flex;
    flex-direction: column;
    margin-left: 12px;
  }
}
