@import "../../variables.scss";

.PlanList {
  align-self: center;
  width: 100%;
  margin-bottom: 6rem;
  &__content-container {
    @media (min-width: $mobile-width-cutoff) {
      padding: 5rem;
    }
    padding: 1rem;

    &__buttons {
      position: sticky;
      bottom: 20px;
    }
  }
}
