@import "../../../variables";
@import "../../../spacing";

.UserProfile {
  flex-direction: column;

  &__container {
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (max-width: $mobile-width-cutoff) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__socials {
    color: $blueberry-550;
    cursor: pointer;
    flex-wrap: wrap;
  }

  &__picture {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: $mobile-width-cutoff) {
      margin-bottom: $sp-3;
    }
  }

  &__bio {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-height: 5em; 
    overflow-wrap: anywhere;

    &--full-description{
      max-height: none;
      text-overflow: initial;
      -webkit-line-clamp: initial;
    }

    &__see-more-button{
      cursor: pointer;
      color: $blueberry-550;
      text-decoration: underline;
    }

    @media (min-width: $mobile-width-cutoff) {
      padding-right: $sp-2;
    }
  }

}

  &__add-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $sp-3;
    row-gap: $sp-3;
    max-width: 524px;

    @media (max-width: $mobile-width-cutoff) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
