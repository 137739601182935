@import "../../../variables";
@import "../../../spacing";


.EditCity {
  position: relative;
  width: 100%;

  
  &__list {
    position: relative;
    z-index: 1;
    border-radius: 0.75rem;
    padding: 7px;
    background-color: #FFFFFF;
    border: 1px solid #CECFC9;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
    list-style: none;

    cursor: pointer;
    &__item {
      max-width: 345px;
      padding: 0.8rem 1rem;
    }
  }
  &__loading {
    border-radius: 0.75rem;
    z-index: 1;
    position: absolute;
    width: 345px;

    &__item {
      padding: 0;
    }
  }
}
