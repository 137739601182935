// Import some component-specific bootstrap styles.
// Importing full bootstrap css ends up over-writing our custom styles.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/offcanvas';

@import '../../variables';

.GetAppBottomDrawer {
  border-radius: 32px 32px 0 0;
  padding-top: 24px;

  &__body {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__description {
      font-weight: 700;
      text-align: center;
    }

    &__app-link {
      margin-top: 24px;
      border-radius: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      vertical-align: middle;
      background: $blueberry-550;
      color: $buttercream;
      padding: 0px 24px;
      font-size: 16px;
      font-weight: 600;
    }

    &__not-now-button {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 600;
      color: $blueberry-550;
    }
  }
}
