@import "../../variables";

.EnhancedConfirmation {
  color: $buttercream;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 0.5rem;

  h2, h3, h4 {
    color: $white;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #EFEEEE;
    width: 100%;
  }

  &__header {
    flex: 0;

    &__title {
      line-height: 1.3;
      margin-bottom: 0.5rem;
    }

    &__datatime {
      margin-bottom: 2rem;
    }

    &__subtitle {
      margin-bottom: 0.5rem;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    flex: 0;
  }

  &__calendars {
    display: flex;
    gap: 8px;

    &__item {
      align-items: center;
      justify-content: center;
      border-radius: 48px;
      border: 2px solid $gray-50;
      color: $white;
      cursor: pointer;
      display: flex;
      flex: 1;
      text-transform: lowercase;
      font-weight: 700;
      gap: 4px;
      padding: 5px 10px;
    }
  }

    &__main {
    &__plans {
      min-height: 200px;

      &__scroll {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
        padding: 2rem 2.5rem;
        margin: 0 -3rem;
        //margin: 0 -2.75rem;
        gap: 1.5rem;
      }
    }
  }

  .Download__btn {
    flex: 1;
    align-items: center;
    background: $bright-pink;
    border-radius: 48px;
    color: $buttercream;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: center;
    padding: 0.875rem 1rem;
    gap: 0.5em;

    &:hover {
      background: $blueberry-750;
    }
  }

  @media (max-width: $mobile-width-cutoff) {
    &__main {
      &__plans {
        &__scroll {
          margin: 0 -2rem;
        }
      }
    }
    &__footer {
      margin-bottom: 3rem;
    }
  }
}
