@import "../../../variables";

.PlanInviteOnlyBanner {
  padding: 20px;
  background-color: $gray-70;
  border-radius: 1.5rem;
  &__icon {
    font-size: 1.5rem;
  }
}
