$sp-0: 0rem;
$sp-1: 0.25rem; // 4px
$sp-2: 0.5rem; // 8px
$sp-3: 0.75rem; // 12px
$sp-4: 1rem; // 16px
$sp-5: 1.25rem; // 16px
$sp-6: 1.5rem; // 24px
$sp-8: 2rem; // 32px
$sp-9: 2.25rem; // 36px
$sp-10: 2.5rem; // 40px
$sp-11: 2.75rem; // 44px
$sp-12: 3rem; // 48px
$sp-20: 5rem;

.M-1 { margin: $sp-1; }
.Mx-1 { margin-right: $sp-1; margin-left: $sp-1; }
.My-1 { margin-top: $sp-1; margin-bottom: $sp-1; }
.Mt-1 { margin-top: $sp-1; }
.Mb-1 { margin-bottom: $sp-1; }
.Mr-1 { margin-right: $sp-1; }
.Ml-1 { margin-left: $sp-1; }
.P-1 { margin: $sp-1; }
.Px-1 { padding-right: $sp-1; padding-left: $sp-1; }
.Py-1 { padding-top: $sp-1; padding-bottom: $sp-1; }
.Pt-1 { padding-top: $sp-1; }
.Pb-1 { padding-bottom: $sp-1; }
.Pr-1 { padding-right: $sp-1; }
.Pl-1 { padding-left: $sp-1; }
.G-1 { gap: $sp-1; }
.Gr-1 { row-gap: $sp-1; }
.Gc-1 { column-gap: $sp-1; }

.M-2 { margin: $sp-2; }
.Mx-2 { margin-right: $sp-2; margin-left: $sp-2; }
.My-2 { margin-top: $sp-2; margin-bottom: $sp-2; }
.Mt-2 { margin-top: $sp-2; }
.Mb-2 { margin-bottom: $sp-2; }
.Mr-2 { margin-right: $sp-2; }
.Ml-2 { margin-left: $sp-2; }
.P-2 { padding: $sp-2; }
.Px-2 { padding-right: $sp-2; padding-left: $sp-2 }
.Py-2 { padding-top: $sp-2; padding-bottom: $sp-2; }
.Pt-2 { padding-top: $sp-2; }
.Pb-2 { padding-bottom: $sp-2; }
.Pr-2 { padding-right: $sp-2; }
.Pl-2 { padding-left: $sp-2; }
.G-2 { gap: $sp-2; }
.Gr-2 { row-gap: $sp-2; }
.Gc-2 { column-gap: $sp-2; }

.M-3 { margin: $sp-3; }
.Mx-3 { margin-right: $sp-3; margin-left: $sp-3; }
.My-3 { margin-top: $sp-3; margin-bottom: $sp-3; }
.Mt-3 { margin-top: $sp-3; }
.Mb-3 { margin-bottom: $sp-3; }
.Mr-3 { margin-right: $sp-3; }
.Ml-3 { margin-left: $sp-3; }
.P-3 { margin: $sp-3; }
.Px-3 { padding-right: $sp-3; padding-left: $sp-3; }
.Py-3 { padding-top: $sp-3; padding-bottom: $sp-3; }
.Pt-3 { padding-top: $sp-3; }
.Pb-3 { padding-bottom: $sp-3; }
.Pr-3 { padding-right: $sp-3; }
.Pl-3 { padding-left: $sp-3; }
.G-3 { gap: $sp-3; }
.Gr-3 { row-gap: $sp-3; }
.Gc-3 { column-gap: $sp-3; }

.M-4 { margin: $sp-4; }
.Mx-4 { margin-right: $sp-4; margin-left: $sp-4; }
.My-4 { margin-top: $sp-4; margin-bottom: $sp-4; }
.Mt-4 { margin-top: $sp-4; }
.Mb-4 { margin-bottom: $sp-4; }
.Mr-4 { margin-right: $sp-4; }
.Ml-4 { margin-left: $sp-4; }
.P-4 { margin: $sp-4; }
.Px-4 { padding-right: $sp-4; padding-left: $sp-4; }
.Py-4 { padding-top: $sp-4; padding-bottom: $sp-4; }
.Pt-4 { padding-top: $sp-4; }
.Pb-4 { padding-bottom: $sp-4; }
.Pr-4 { padding-right: $sp-4; }
.Pl-4 { padding-left: $sp-4; }
.G-4 { gap: $sp-4; }
.Gr-4 { row-gap: $sp-4; }
.Gc-4 { column-gap: $sp-4; }

.P-5 { padding: $sp-5; }
.Px-5 { padding-right: $sp-5; padding-left: $sp-5; }

.M-6 { margin: $sp-6; }
.Mx-6 { margin-right: $sp-6; margin-left: $sp-6; }
.My-6 { margin-top: $sp-6; margin-bottom: $sp-6; }
.Mt-6 { margin-top: $sp-6; }
.Mb-6 { margin-bottom: $sp-6; }
.Mr-6 { margin-right: $sp-6; }
.Ml-6 { margin-left: $sp-6; }
.P-6 { padding: $sp-6; }
.Px-6 { padding-right: $sp-6; padding-left: $sp-6; }
.Py-6 { padding-top: $sp-6; padding-bottom: $sp-6; }
.Pt-6 { padding-top: $sp-6; }
.Pb-6 { padding-bottom: $sp-6; }
.Pr-6 { padding-right: $sp-6; }
.Pl-6 { padding-left: $sp-6; }
.G-6 { gap: $sp-6; }
.Gr-6 { row-gap: $sp-6; }
.Gc-6 { column-gap: $sp-6; }

.Mt-8 { margin-top: $sp-8; }
.Mb-8 { margin-bottom: $sp-8; }

.G-9 { gap: $sp-9; }

.P-12 { padding: $sp-12; }

.M-12 { margin: $sp-12; }
.Mt-12 { margin-top: $sp-12; }
.Mb-12 { margin-bottom: $sp-12; }
.Mr-20 { margin-right: $sp-20; }
