@import "../../../variables";
@import "../../../spacing";

.ChannelHeader {

  &__image {
    object-fit: cover;
    height: 160px;
    width: 160px;
    border-radius: $sp-3;

    &--joined-plan {
      height: 11.25rem;
      width: 11.25rem;
      margin-left: $sp-6;
    }
  }

  &__links {
    margin-top: $sp-3;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $sp-3;

    &__anchor {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: $sp-1;
      border: 2px solid $raised-surface-border-color;
      border-radius: 48px;
      box-sizing: border-box;
      padding: $sp-2 $sp-4;
      color: $blueberry-550;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        border: 2px solid $bright-pink-tenth-opacity;
      }
    }
  }

  &__creator {
    display: flex;
    align-items: center;
    margin-bottom: $sp-3;

    &__image {
      object-fit: cover;
      height: 2.25rem;
      width: 2.25rem;
      border-radius: 50%;
      box-sizing: border-box;
      margin-right: $sp-2;
    }
  }

  &--joined-plan {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: $mobile-width-cutoff) {
    &__picture-title {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__image {
      &--joined-plan {
        height: 5rem;
        width: 5rem;
        margin-left: $sp-6;
        margin-bottom: $sp-0;
      }
    }
  }
}
